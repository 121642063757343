import { alpha } from '@mui/material';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { makeTheme } from './baseTheme';
import {
  shuttlerockBlue,
  gradient,
  grey,
  green,
  red,
  amber,
  purple,
} from './darkColors';
import { FaIcon } from '../components/DataDisplay/Icon/FaIcon';
import type {} from '@mui/types';

/**
 * `DarkTheme` defines the colour palette used in dark mode, along with component overrides that are specific
 * to dark mode
 *
 * Typically, this includes styles that influence colours
 */
export const DarkTheme = makeTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: shuttlerockBlue[300],
      main: shuttlerockBlue[500],
      dark: shuttlerockBlue[600],
      contrastText: grey[900],
      hover: shuttlerockBlue[300],
      active: shuttlerockBlue[300],
    },
    secondary: {
      light: grey[300],
      main: grey[200],
      dark: grey[100],
      contrastText: grey[900],
    },
    error: {
      light: red[400],
      main: red[500],
      dark: red[600],
      contrastText: grey[50],
    },
    info: {
      light: shuttlerockBlue[100],
      main: shuttlerockBlue[400],
      dark: shuttlerockBlue[700],
      contrastText: grey[900],
    },
    success: {
      light: green[300],
      main: green[500],
      dark: green[600],
      contrastText: grey[50],
    },
    warning: {
      background: amber[400],
      light: amber[400],
      main: amber[500],
      dark: amber[600],
      contrastText: grey[50],
      active: amber[300],
    },
    background: {
      default: grey[50],
      paper: grey[100],
      secondary: grey[200],
      card: grey.A100,
      cardHover: grey.A200,
      cardAlt: grey.A200,
      cardAltHover: grey.A300,
    },
    foreground: {
      main: grey[200],
      secondary: grey[100],
      tertiary: grey[300],
      hover: grey.A200,
    },
    text: {
      primary: grey.A900,
      primaryAlt: grey.A800,
      secondary: grey.A600,
      disabled: grey.A400,
    },
    stroke: {
      main: grey.A100,
      hover: grey.A500,
      active: shuttlerockBlue[300],
      dark: grey.A200,
    },
    divider: grey[50],
    gradient: {
      main: gradient.main,
      light: gradient.hover,
      background: gradient.background,
      blueToPurple: gradient.blueToPurple,
      purpleToBlue: gradient.purpleToBlue,
      pinkToOrange: gradient.pinkToOrange,
      contrastText: '#fff',
    },
    automation: {
      light: purple[300],
      main: purple[500],
      dark: purple[700],
      contrastText: grey[50],
    },
    instructions: {
      light: grey[300],
      main: grey[200],
      dark: grey[100],
      contrastText: purple[900],
    },
    action: {
      hover: 'rgba(255, 255, 255, .2)',
    },
    gender: {
      all: green[500],
      female: purple[500],
      male: shuttlerockBlue[500],
    },
    grey,
  },
  components: {
    MuiAlert: {
      defaultProps: {
        variant: 'outlined',
        iconMapping: {
          info: <FaIcon icon={faCircleInfo} />,
        },
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.variant === 'filled' &&
            ownerState?.color === 'automation' && {
              '&.MuiAlert-filledAutomation': {
                backgroundColor: purple[200],
                color: purple[700],
                '& .MuiAlert-icon': {
                  color: purple[700],
                  opacity: 'initial',
                },
              },
            }),
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          ...(ownerState?.variant === 'contained' &&
            ownerState?.color === 'primary' && {
              '&:hover': {
                backgroundColor: shuttlerockBlue[400],
              },
            }),
          ...(ownerState?.variant === 'contained' &&
            ownerState?.color === 'info' && {
              '&:hover': {
                backgroundColor: shuttlerockBlue[400],
              },
            }),
          ...(ownerState?.variant === 'contained' &&
            ownerState?.color === 'error' && { color: grey[100] }),
          ...(ownerState?.color === 'secondary' && { color: grey.A700 }),
        }),
      },
      variants: [
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            backgroundColor: grey.A200,
            ':hover': {
              backgroundColor: grey.A300,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            '&:hover': {
              backgroundColor: shuttlerockBlue[300],
            },
            '&:active': {
              backgroundColor: shuttlerockBlue[300],
            },
            '&[disabled]': {
              borderColor: grey[400],
            },
          },
        },
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            color: shuttlerockBlue[300],
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState?.variant === 'm3' && {
            ...(ownerState?.clickable && {
              '&:hover::after': {
                position: 'absolute',
                inset: 0,
                content: '""',
                borderRadius: '4px',
                backgroundColor: 'rgba(255, 255, 255, 0.15)',
              },
            }),
            ...(ownerState?.color === 'success' && {
              color: green[800],
              '&, &:hover': {
                backgroundColor: green[100],
              },
            }),
            ...(ownerState?.color === 'warning' && {
              color: amber[800],
              '&, &:hover': {
                backgroundColor: amber[200],
              },
            }),
            ...(ownerState?.color === 'error' && {
              color: red[800],
              '&, &:hover': {
                backgroundColor: red[200],
              },
            }),
            ...(ownerState?.color === 'secondary' && {
              color: theme.palette.grey[50],
              '&, &:hover': {
                backgroundColor: theme.palette.grey[700],
              },
            }),
            ...(ownerState?.color === 'default' && {
              color: theme.palette.grey.A700,
              '&, &:hover': {
                backgroundColor: theme.palette.grey[50],
              },
            }),
            ...((ownerState?.color === 'primary' ||
              ownerState?.color === 'info') && {
              '&, &:hover': {
                backgroundColor: theme.palette.primary.main,
              },
            }),
          }),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '::selection': {
          backgroundColor: alpha(shuttlerockBlue[500], 0.3),
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: grey['100'],
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            backgroundColor: grey[50],
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: grey.A800,
          '&.Mui-selected': {
            color: grey.A900,
            backgroundColor: grey.A200,
          },
          '&:hover': {
            color: grey.A900,
            backgroundColor: grey.A300,
          },
          '&.Mui-selected:hover': {
            color: grey.A900,
            backgroundColor: grey.A300,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          color: grey.A900,
        },
      },
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const readOnly = ownerState?.readOnly;

          return readOnly
            ? {
                backgroundColor: 'transparent',
                boxShadow: 'none',
                fieldset: { border: 'none' },
              }
            : {
                backgroundColor: grey[50],
                boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.024)',
                fieldset: { borderColor: grey[200] },
                '&.Mui-disabled': {
                  backgroundColor: grey[200],
                  fieldset: {
                    borderColor: `${grey[200]} !important`,
                  },
                },
                '&:hover:not(:focus-within):not(.Mui-disabled):not(.Mui-error) fieldset':
                  {
                    borderColor: grey[500],
                  },
                '&:focus-within:not(.Mui-error) fieldset': {
                  borderColor: shuttlerockBlue[400],
                  borderWidth: '1px',
                },
              };
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          color: grey.A300,
        },
        mark: {
          backgroundColor: grey.A200,
          height: 3,
          width: 3,
        },
        root: {
          '.MuiSlider-markActive': {
            backgroundColor: grey.A600,
          },
        },
        track: {
          border: 'none',
          color: shuttlerockBlue[400],
        },
        thumb: {
          color: shuttlerockBlue[400],
        },
        valueLabel: {
          backgroundColor: grey[400],
        },
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            height: '6px',
          },
        },
      ],
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          color: red['50'],
          '& .MuiAlert-filledError': {
            backgroundColor: red['800'],
            color: 'inherit',
            '& .MuiAlert-message > :last-child': {
              color: red['300'],
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          color: grey['700'],
          fontWeight: 500,
          '&.Mui-completed': {
            color: grey['700'],
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: grey['50'],
          '&.Mui-active': {
            color: 'transparent',
            borderColor: grey['700'],
          },
          '&.Mui-completed': {
            '&.Mui-active': {
              color: grey['50'],
              backgroundColor: green['500'],
            },
            color: green['500'],
            borderColor: green['500'],
          },
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          color: grey['600'],
          borderLeftColor: `${grey['300']}`,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: grey['300'],
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorPrimary: { color: shuttlerockBlue[500] },
        root: {
          '&.MuiSvgIcon-colorSuccess': {
            color: green[500],
          },
          '&.MuiSvgIcon-colorError': {
            color: red[500],
          },
          '&.MuiSvgIcon-colorAutomation': {
            color: purple[700],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: grey[800],
          '&:hover': {
            backgroundColor: grey[200],
          },
          '&.Mui-selected': {
            backgroundColor: grey.A100,
            color: grey[800],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          '& .MuiTabs-flexContainer:after': {
            backgroundColor: grey[50],
          },
          '&:before': {
            backgroundColor: grey[50],
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: grey[100],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          color: grey[700],
        },
        tooltip: {
          backgroundColor: grey[700],
          color: grey[100],
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          '&.MuiLinearProgress-colorPrimary': {
            backgroundColor: grey[300],
          },
          '& > .MuiLinearProgress-barColorPrimary': {
            backgroundColor: shuttlerockBlue[300],
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState?.variant === 'overline' && {
            color: purple[700],
          }),
          ...(ownerState?.color === 'gradient' && {
            background: gradient.main,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }),
        }),
      },
    },
  },
});
